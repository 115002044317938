import { RoosterBattle } from './contracts/RoosterBattle.ts';
import { useTonClient } from './hooks/useTonClient';
import { useTonConnect } from './hooks/useTonConnect';
import { Address, OpenedContract } from '@ton/core';
import { getHttpEndpoint } from '@orbs-network/ton-access';
import { TonClient } from "@ton/ton";


async function getContract(){
    const client = new TonClient({
        endpoint: await getHttpEndpoint({ network: 'mainnet' }),
      });
    console.log("client",client)
    if (!client) return;
    const contract = new RoosterBattle(
      Address.parse('EQDLDoU-eA0jzGAMQ8aEN5Pur_OFIgZYAAfPmGbH9OFMCYJP') // replace with your address from tutorial 2 step 8
    );
    return client.open(contract);
  }
  export { getContract }; 