import { Scene } from 'phaser';
import { ensureTonConnectRoot, tonConnectUI } from '../main';
import { Address } from '@ton/core';
import { getContract } from '../contract-connect';
import { Sender } from '@ton/core';

export class Reward extends Scene {
    constructor() {
        super('Reward');
    }

    fontStyle = {
        fontFamily: 'Barlow Condensed',
        fontSize: 45,
        color: '#ffffff',
        stroke: '#000000',
        strokeThickness: 3,
    }

    generateFontstyle(fontSize = 45, offset = 1, color = '#ffffff', strokeThickness = 3) {
        return {
            ...this.fontStyle,
            fontSize,
            color,
            strokeThickness,
            shadow: {
                offsetX: offset,
                offsetY: offset,
                color: '#000000',
                blur: 1,
                fill: true
            }
        };
    }
    formatAddress(address) {
        return address.length <= 8 ? address : `${address.slice(0, 4)}...${address.slice(-4)}`;
    }

    async checkWallet(connectBtn, checkinBtn, connectWalletText, connectBtnText, checkinText) {
        await ensureTonConnectRoot();
            const account = tonConnectUI.account;
            if (account && account.address) {
                const parsedAddress = Address.parse(account.address);
                const address = parsedAddress.toString({ bounceable: false });
                const formattedAddress = this.formatAddress(address);
                connectBtn.setTexture('roosterbattle', 'button_red_active');
                checkinBtn.setTexture('roosterbattle', 'button_green_active');
                checkinBtn.setInteractive();
                connectWalletText.setText(formattedAddress);
                connectBtnText.setText('Disconnect');

                const contract = await getContract();
                const isCheckin = await contract.getIsCheckedIn(parsedAddress);

                if (isCheckin) {
                    checkinBtn.setTexture('roosterbattle', 'button_brown_disable');
                    checkinText.setText('Completed');
                    checkinBtn.disableInteractive();
                    return;
                }
            else {
                console.error('No address found in account');
            }
        } else {
            connectBtn.setTexture('roosterbattle', 'button_green_active');
            connectBtn.setInteractive();
            checkinBtn.setTexture('roosterbattle', 'button_brown_disable');
            checkinBtn.disableInteractive();
            connectWalletText.setText('Connect Wallet');
            connectBtnText.setText('Connect');
        }
    }

    startCheckingConnection(connectBtn, checkinBtn, connectWalletText, connectBtnText, checkinText) {
        this.connectionCheckInterval = setInterval(async () => {
            await this.checkWallet(connectBtn, checkinBtn, connectWalletText, connectBtnText, checkinText);

            var isCheckin = false; 

            if(tonConnectUI.account){
                const contract = await getContract();
                const account = tonConnectUI.account.address;
                const parsedAddress = Address.parse(account);

                connectBtn.setTexture('roosterbattle', 'button_red_active');
                connectBtnText.setText('Disconnect');
                checkinBtn.setTexture('roosterbattle', 'button_green_active');
                checkinBtn.setInteractive();
                isCheckin = await contract.getIsCheckedIn(parsedAddress);
            }

            if (isCheckin) {
                checkinBtn.setTexture('roosterbattle', 'button_brown_disable');
                checkinText.setText('Completed');
                checkinBtn.disableInteractive();
                clearInterval(this.connectionCheckInterval)
            }
        }, 2000);
    }


    startCheckingCheckinStatus(checkinText,checkinBtn) {
        this.CheckinInterval = setInterval(async () => {
            const contract = await getContract();
            const account = tonConnectUI.account.address;
            const parsedAddress = Address.parse(account);
            const isCheckin = await contract.getIsCheckedIn(parsedAddress);
            console.log("startCheckingCheckinStatus",isCheckin )
            if (isCheckin) {
                checkinBtn.setTexture('roosterbattle', 'button_brown_disable');
                checkinText.setText('Completed');
                checkinBtn.disableInteractive();
                clearInterval(this.CheckinInterval)
            }
        }, 2000);
    }

    stopCheckingConnection() {
        if (this.connectionCheckInterval) {
            clearInterval(this.connectionCheckInterval);
        }
    }

    async create() {
        this.createCloseButton(this.cameras.main.width - 30, 1550);

        this.add.nineslice(30, 130, 'roosterbattle', 'popup_bg', 660, 1010, 45, 45, 45, 45).setOrigin(0, 0).setInteractive();
        this.add.nineslice(140, 90, 'roosterbattle', 'popup_title_bg', 440, 80, 80, 80, 40, 40).setOrigin(0, 0).setInteractive();
        this.add.text(305, 100, 'Reward', this.generateFontstyle());
        this.add.nineslice(90, 320, 'roosterbattle', 'popup_row_bg', 560, 90, 65, 65, 25, 35).setOrigin(0, 0);
        this.add.nineslice(90, 450, 'roosterbattle', 'popup_row_bg', 560, 90, 65, 65, 25, 35).setOrigin(0, 0);
        const connectBtn = this.add.nineslice(510, 335, 'roosterbattle', 'button_brown_disable', 120, 50, 30, 30, 20, 20).setOrigin(0, 0).setInteractive()
        const checkinBtn = this.add.nineslice(510, 465, 'roosterbattle', 'button_brown_disable', 120, 50, 30, 30, 20, 20).setOrigin(0, 0).setInteractive()

        const connectWalletText = this.add.text(115, 345, 'Connect Wallet', this.generateFontstyle(30));
        const connectBtnText = this.add.text(570, 358, 'Connect', this.generateFontstyle(25)).setOrigin(0.5, 0.5).setAlign('center');
        const checkinText = this.add.text(570, 488, 'Go', this.generateFontstyle(25)).setOrigin(0.5, 0.5).setAlign('center');

        checkinBtn.disableInteractive();

        if (tonConnectUI && tonConnectUI.account) {
            connectBtn.setInteractive();
        } else {
            await ensureTonConnectRoot();
            checkinBtn.disableInteractive();
            
        }

        connectBtn.on('pointerup', async () => {
            
            if (tonConnectUI && tonConnectUI.account) {
                await tonConnectUI.disconnect();
                connectBtn.setTexture('roosterbattle', 'button_green_active');
                checkinBtn.setTexture('roosterbattle', 'button_brown_disable');
                checkinBtn.disableInteractive();
                checkinText.setText('Go');
                connectWalletText.setText('Connect Wallet');
                connectBtnText.setText('Connect');
            } else {
                await ensureTonConnectRoot();
                await tonConnectUI.openModal();             
                this.startCheckingConnection(connectBtn, checkinBtn, connectWalletText, connectBtnText, checkinText);
                const contract = await getContract();
                const account = tonConnectUI.account.address;
                const parsedAddress = Address.parse(account);
                const isCheckin = await contract.getIsCheckedIn(parsedAddress);
                if (!isCheckin) {
                    checkinBtn.disableInteractive();
                    checkinBtn.setTexture('roosterbattle', 'button_brown_disable');
                    checkinText.setText('Completed');
                    this.stopCheckingConnection();
                }
            }
        });

        checkinBtn.on('pointerup', async () => {
            checkinBtn.disableInteractive();
            checkinBtn.setTexture('roosterbattle', 'button_brown_disable');
            const contract = await getContract();
            const sender = {
                send: async (args) => {
                    tonConnectUI.sendTransaction({
                        messages: [
                            {
                                address: args.to.toString(),
                                amount: args.value.toString(),
                                payload: args.body?.toBoc().toString('base64'),
                            },
                        ],
                        validUntil: Date.now() + 5 * 60 * 1000,
                    });
                },
            };

            const send = await contract.sendCheckin(sender, 0.11);
            this.startCheckingCheckinStatus()

        });

        this.add.text(115, 475, 'Check in (Min 0.1 Ton)', this.generateFontstyle(30));
        this.checkWallet(connectBtn, checkinBtn, connectWalletText, connectBtnText, checkinText);
    }

    createCloseButton() {
        const backButton = this.add.rectangle(400, 500, 800, 2000, 0xFF0000, 0).setInteractive();
        backButton.on('pointerdown', () => {
            if (!this.overlay) {
                this.stopCheckingConnection();
                this.scene.stop('Reward');
                const mainMenu = this.scene.get('MainMenu');
                mainMenu.hideOverlay();
                mainMenu.scene.resume();
            }
        });
        return backButton;
    }
}