import { Boot } from './scenes/Boot';
import { Game } from './scenes/Game';
import { GameOver } from './scenes/GameOver';
import { MainMenu } from './scenes/MainMenu';
import { Preloader } from './scenes/Preloader';
import { Popup_equipSlot } from './scenes/Popup_equipSlot';
import { Leaderboard } from './scenes/Leaderboard';
import { Hatch } from './scenes/Hatch';
import { Reward } from './scenes/Reward';
import { Reward } from './scenes/Reward';
import WebFontLoaderPlugin from 'phaser3-rex-plugins/plugins/webfontloader-plugin.js';
import ShakePositionPlugin from 'phaser3-rex-plugins/plugins/shakeposition-plugin.js';
import EaseMovePlugin from 'phaser3-rex-plugins/plugins/easemove-plugin.js';
import RexUIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin.js';

import { TonConnectUI } from '@tonconnect/ui';
import { SpinePlugin } from '@esotericsoftware/spine-phaser';
import { Address } from '@ton/core';
import axios from 'axios';
import { initializeApp, getApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { getAuth, signInWithCustomToken, updateProfile } from 'firebase/auth';

let tonConnectUI;

const firebaseConfig = {
    apiKey: 'AIzaSyCZK_uitYBEEl1Ol48KyUeC_ojGaX9KZo0',
    authDomain: 'rooster-battle-ton.firebaseapp.com',
    projectId: 'rooster-battle-ton',
    storageBucket: 'rooster-battle-ton.appspot.com',
    messagingSenderId: '945101829011',
    appId: '1:945101829011:web:792a6d6c826942023f6e66',
    measurementId: 'G-NL46J330PD',
};

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

getData = async () => {
    console.log(Telegram.WebApp.initData);
    const debug = 0;

    var server_url = 'https://helloworld-jsjsbapnhq-uc.a.run.app?data=' + btoa(Telegram.WebApp.initData);
    if(debug){
        server_url = 'https://helloworld-jsjsbapnhq-uc.a.run.app?uid=0000000&data=' + btoa(Telegram.WebApp.initData);
    }

    const response = await axios.post(
        server_url,
        {
            firstName: 'Fred',
            lastName: 'Flintstone',
            authenData: Telegram.WebApp.initData,
        },
        {
            headers: {
                'authen-data': Telegram.WebApp.initData,
            },
        }
    );
    const token = response.data;

    const auth = getAuth();
    const userCredential = await signInWithCustomToken(auth, token);
    console.log(userCredential);

    await updateProfile(auth.currentUser, {
        displayName: debug ? "TEST" : (Telegram.WebApp.initDataUnsafe.user.first_name + ' ' + Telegram.WebApp.initDataUnsafe.user.last_name),
    });

    return userCredential.user;
};

function addTonConnectElement() {
    if (!document.getElementById('ton-connect')) {
        const tonConnectDiv = document.createElement('div');
        tonConnectDiv.id = 'ton-connect';
        document.body.appendChild(tonConnectDiv);
        console.log('ton-connect element added to DOM');
    }
}

ensureTonConnectRoot = () => {
    return new Promise((resolve, reject) => {
        addTonConnectElement();
        const checkExist = setInterval(() => {
            console.log('Checking for ton-connect element...');
            const element = document.getElementById('ton-connect');
            if (element) {
                clearInterval(checkExist);
                if (!tonConnectUI) {
                    tonConnectUI = new TonConnectUI({
                        manifestUrl: 'https://legacy.roosterbattle.net/ton-manifest.json', 
                    });
                    console.log('tonConnectUI initialized:', tonConnectUI);
                }
                resolve();
            }
        }, 100);
    });
};





const startBattle = async () => {
    const startBattle = httpsCallable(functions, 'startBattle');
    const result = await startBattle();
    return result.data;
};

const summonItem = async () => {
    const summonItem = httpsCallable(functions, 'summonItem');
    const result = await summonItem();
    return result.data;
};

equipPendingItem = async () => {
    const equipPendingItem = httpsCallable(functions, 'equipPendingItem');
    const result = await equipPendingItem();
    console.log(result.data);
    return result.data;
};

capitalizeFirstLetter = (string) => {
    console.log('String value:', string);
    if (string && typeof string === 'string') {
        return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
        console.error('Invalid string:', string);
        return '';
    }
};

gotoTelegramLink = async url => {
	Telegram.WebApp.openTelegramLink(url);
};

getLeaderboard = async () => {
    const getLeaderboard = httpsCallable(functions, 'getLeaderboard');
    const result = await getLeaderboard();
    console.log(result.data);
    return result.data;
};

PendingItem = async () => {
	const PendingItem = httpsCallable(functions, 'PendingItem');
	const result = await PendingItem();
	console.log(result.data);
	return result.data;
};

getMyReferral = async () => {
    const myReferral = httpsCallable(functions, 'myReferral');
    const result = await myReferral();
    return result.data;
};

getMyReferralLink = async () => {
    const getMyRefLink = httpsCallable(functions, 'getMyRefLink');
    const result = await getMyRefLink();
    return result.data;
};

//  Find out more information about the Game Config at:
//  https://newdocs.phaser.io/docs/3.70.0/Phaser.Types.Core.GameConfig
const config = {
    type: Phaser.AUTO,
    width: 720,
    height: 1280,
    parent: 'game-container',

    scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
    },

    scene: [Boot, Preloader, MainMenu, Game, GameOver, Leaderboard, Hatch, Reward, Popup_equipSlot],

    plugins: {
        scene: [
            { key: 'spine.SpinePlugin', plugin: SpinePlugin, mapping: 'spine', sceneKey: 'spine' },
            { key: 'rexUI', plugin: RexUIPlugin, mapping: 'rexUI', start: true }
        ],
        global: [
            {
                key: 'rexWebFontLoader',
                plugin: WebFontLoaderPlugin,
                start: true,
            },
            {
                key: 'rexEaseMove',
                plugin: EaseMovePlugin,
                start: true,
            },
            {
                key: 'rexShakePosition',
                plugin: ShakePositionPlugin,
                start: true,
            },
        ],
    },
};

export default new Phaser.Game(config);
export { getData, startBattle, summonItem, equipPendingItem, capitalizeFirstLetter, getLeaderboard, getMyReferral, getMyReferralLink,PendingItem, tonConnectUI, ensureTonConnectRoot};
