import { Scene } from 'phaser';
import { Skin } from '@esotericsoftware/spine-phaser';
import { startBattle, getData, summonItem } from '../main';
import ImageButton from '../components/ImageButton';
import TabBarButton from '../components/TabBarButton';
import { PendingItem } from '../main';
import { equipPendingItem } from '../main';
import NestEgg from '../components/NestEgg';
import EquipmentPanel from '../components/mainscene_component/EquipmentPanel';
import ProgressBar from '../components/ProgressBar';
import FloatingNumbersPlugin from '../components/FloatingNumbersPlugin';
import CurrencySlot from '../components/CurrencySlot'; 

export class MainMenu extends Scene {
    constructor() {
        super('MainMenu');
    }

    preload() {
        this.load.scenePlugin('floatingNumbersPlugin', FloatingNumbersPlugin, 'floatingNumbersPlugin', 'floatingNumbers');
        this.load.atlas('roosterbattle', 'assets/roosterbattle_main.png', 'assets/roosterbattle_main.json');
    }
    fontStyle = {
        fontFamily: 'Barlow Condensed',
        fontSize: 45,
        color: '#ffffff',
        stroke: '#000000',
        strokeThickness: 2,
        shadow: {
            offsetX: 10,
            offsetY: 10,
            color: '#000000',
            blur: 10,
            fill: true
        }
    }

    generateFontstyle(fontSize = 45, offset = 10 ) {
        var newFontStyle = this.fontStyle
        newFontStyle.fontSize = fontSize
        newFontStyle.shadow.offsetX = offset
        newFontStyle.shadow.offsetY = offset
        return newFontStyle  
    }
    create() {
        
        // Background and UI elements setup
        this.gamebg = this.add.image(0, 0, 'main', 'bg').setOrigin(0.5, 0.5);
        this.gamebg.x = this.gamebg.width / 2;
        this.gamebg.y = this.gamebg.height / 2;

        this.bottomBar = this.add.image(0, 0, 'main', 'bottom_menu_bg').setOrigin(0.5, 0.5);
        this.bottomBar.x = this.bottomBar.width / 2;
        this.bottomBar.y = this.gamebg.height - 110;

        this.inventorybg = new EquipmentPanel(this, this.gamebg.x, this.gamebg.y + 120);
        this.eggBtn = new NestEgg(this, this.gamebg.x, this.bottomBar.y + 30);
        this.button1 = new TabBarButton(this, 80, this.bottomBar.y + 55, 'button_brown', 'Battle');
        this.button2 = new TabBarButton(this, 210, this.bottomBar.y + 55, 'button_brown', 'Upgrade');
        this.button3 = new TabBarButton(this, 510, this.bottomBar.y + 55, 'button_brown', 'Leaderboard');
        this.button4 = new TabBarButton(this, 640, this.bottomBar.y + 55, 'button_brown', 'Reward');

        this.coinSlot = new CurrencySlot(this, 130, 50, 'token_rgold', 200);
        this.button3.on('pointerdown', () => {
            this.button3.bg.setTexture('main', 'button_brown_normal'); 
            this.showOverlay(0.5);
            this.scene.launch('Leaderboard');
        });
        
        this.eggBtn.on('pointerup', async () => {
            if (this.coinSlot.currentNumber < 100) {
                console.warn('ko đủ coin.');
                return;
            }
            this.eggBtn.shakeEgg();
            const overlay = this.showOverlay(0); 
            try {
                const eggPrice = this.eggBtn.price;
                const itemData = await summonItem();
                if (this.eggBtn.shakeEffect) {
                    this.eggBtn.stopShakeEffect();
                }
        
                if (itemData.data.success === false) {
                    console.warn('Summon item failed:', itemData.data);
                } else {
                    this.eggBtn.setEggPrice(itemData.data.price);
                    this.coinSlot.updateNumber(this.coinSlot.currentNumber - eggPrice);
                    this.eggBtn.setEggStatus(this.coinSlot.currentNumber >= this.eggBtn.price);
                    if (overlay) {
                        overlay.clear().fillStyle(0x000000, 0.5).fillRect(0, 0, this.scale.width, this.scale.height);
                    }
                    try {
                        const currentEquip = this.myEquipments[itemData.data.position];
                        this.scene.launch('Hatch', { currentEquip, upgradeEquip: itemData });
                        overlay.on('pointerdown', () => {
                            this.scene.stop('Hatch');
                            this.hideOverlay();
                        });
                    } catch (error) {
                        console.error('Error while equipping item:', error);
                        this.hideOverlay();
                    }
                }
            } catch (error) {
                console.error('Error while summoning item:', error);
                if (this.eggBtn.shakeEffect) {
                    this.eggBtn.stopShakeEffect();
                }
                this.hideOverlay();
            }
        });
        
        this.button4.on('pointerdown', () => {
            this.button4.bg.setTexture('main', 'button_brown_normal'); 
            this.showOverlay(0.5);
            this.scene.launch('Reward');
        });

        this.battleInfo = [];
        this.currentTurn = -1;
        this.triggerTimer = this.time.addEvent({
            callback: this.timerEvent,
            callbackScope: this,
            delay: 4150,
            loop: true,
        });
        this.loadNewOpponent();
    }

    showOverlay(opacity = 0.5) {
        if (this.overlay) return;
        this.overlay = this.add.graphics();
        this.overlay.fillStyle(0x000000, opacity);
        this.overlay.fillRect(0, 0, this.scale.width, this.scale.height);  
        this.overlay.setInteractive(new Phaser.Geom.Rectangle(0, 0, this.scale.width, this.scale.height), Phaser.Geom.Rectangle.Contains);
        this.input.setTopOnly(true);
        this.disableAllButtons()
        return this.overlay;
    }
    hideOverlay() {
        if (!this.isShowVictoryScreen && this.overlay) {
            this.overlay.destroy();
            this.overlay = null;
            this.time.delayedCall(300, () => {
                this.enableAllButtons();
            });
        }
        this.isHavingModal = false;
    }

    timerEvent() {
		if (this.battleInfo == null || this.battleInfo.length == 0 || this.currentTurn >= this.battleInfo.length - 1) {
			this.resetBattleField();
			this.loadNewOpponent();
			return;
		}
		this.currentTurn += 1;

		var attackChar = null;
		var hitChar = null;
		var attackSkill = null;
		var hpTrack = null;
		var skillBtn = null;

		if (this.currentTurn % 2 == 0) {
			attackChar = this.rightPlayer;
			//attackSkill = this.rightSkill;
			hitChar = this.leftPlayer;
			hpTrack = this.leftHP;
			//skillBtn = this.leftSkill;
		} else {
			attackChar = this.leftPlayer;
			//attackSkill = this.leftSkill;
			hitChar = this.rightPlayer;
			hpTrack = this.rightHP;
			//skillBtn = this.rightSkill;
		}

		attackChar.animationState.addListener({
			complete: entry => {
				attackChar.animationState.clearListeners();

				if (this.battleInfo[this.currentTurn].newHP >= 0) {
					attackChar.animationState.setAnimation(0, 'idle', true);
				}
			},
			event: (entry, event) => {
				if (event.data.name == 'hit') {
					console.log('event hit');
					/*if (attackSkill.skillName == 'skill_1' && this.battleInfo[this.currentTurn].isSkill) {
                        var impact = this.add.spine(hitChar.x, hitChar.y, 'dogkong-impact-data', 'dogkong-impact-atlas');
                        impact.animationState.addListener({
                            complete: entry => {
                                this.scene.remove(impact);
                                impact.destroy();
                            },
                        });
    
                        impact.setOrigin(0.5, 0.5);
                        impact.scaleX = 0.5;
                        impact.scaleY = 0.5;
                        impact.animationState.setAnimation(0, 'Skill', false);
                    }
                    if (attackSkill.skillName == 'skill_2' && this.battleInfo[this.currentTurn].isSkill) {
                        this.createNumber(hitChar, 5);
    
                        
                    } else {
                        
                    }*/

					this.createNumber(hitChar);
                    console.log(this.battleInfo[this.currentTurn])
					if (hitChar == this.rightPlayer) {
						/* if (attackSkill.skillName == 'skill_2' && this.battleInfo[this.currentTurn].isSkill) {
                            this.createReward(1);
                        } else {
                            this.createReward();
                        }*/
						this.createReward();
                        this.coinSlot.updateNumber(this.coinSlot.currentNumber + (this.battleInfo[this.currentTurn].coinDrop ? this.battleInfo[this.currentTurn].coinDrop : 0)  );
                        this.eggBtn.setEggStatus(this.coinSlot.currentNumber >=this.eggBtn.price ? true : false);;
                    }

					hpTrack.setProgress(this.battleInfo[this.currentTurn].newHP);
					//skillBtn.setProgress(this.battleInfo[this.currentTurn].skillGauge);

					if (this.battleInfo[this.currentTurn].newHP < 0) {
						hitChar.animationState.setAnimation(0, 'die', false);
						attackChar.animationState.setAnimation(0, 'victory', true);
                        if (hitChar == this.rightPlayer) {
                            this.showVictoryScreen(); 
                        }
                        
					} else {
						hitChar.animationState.setAnimation(0, 'hit 1', false);
						hitChar.animationState.addAnimation(0, 'idle', true);
					}
				}
			},
		});

		/*if (this.battleInfo[this.currentTurn].isSkill) {
            attackChar.animationState.setAnimation(0, 'Skill', false);
            attackSkill.resetSkill();
        } else {
            attackChar.animationState.setAnimation(0, 'NormalAttack', false);
        }*/

		attackChar.animationState.setAnimation(0, 'attack', false);
        
	}

    disableAllButtons() {
        this.button1.disableInteractive();
        this.button2.disableInteractive();
        this.button3.disableInteractive();
        this.button4.disableInteractive();
        this.eggBtn.disableInteractive();
    }
    
    enableAllButtons() {
        this.button1.setInteractive();
        this.button2.setInteractive();
        this.button3.setInteractive();
        this.button4.setInteractive();
        this.eggBtn.setInteractive();
    }

    createNumber(targetObj) {
        if (this.currentTurn >= this.battleInfo.length) {
            return;
        }

        const damage = Math.floor(this.battleInfo[this.currentTurn].damage);
        this.floatingNumbers.createFloatingText({
            textOptions: {
                fontFamily: 'Barlow Condensed',
                fontSize: 42,
                color: '#fff',
                strokeThickness: 2,
                fontWeight: 'bold',
                stroke: '#000000',
                shadow: {
                    offsetX: 0,
                    offsetY: 0,
                    color: '#000',
                    blur: 4,
                    stroke: true,
                    fill: false,
                },
            },
            text: damage,
            align: 'top-center',
            parentObject: targetObj,
            animation: 'up',
            timeToLive: 500,
            animationEase: 'Sine.easeOut',
        });
    }

    resetBattleField() {
        if (this.leftPlayer) {
            this.leftPlayer.destroy();
        }
        if (this.rightPlayer) {
            this.rightPlayer.destroy();
        }
        if (this.myName) {
            this.myName.destroy();
        }
        if (this.oppName) {
            this.oppName.destroy();
        }
        if (this.leftSkill) {
            this.leftSkill.destroy();
        }
        if (this.rightSkill) {
            this.rightSkill.destroy();
        }
        if (this.leftHP) {
            this.leftHP.destroy();
        }
        if (this.rightHP) {
            this.rightHP.destroy();
        }
        this.battleInfo = null;
        this.currentTurn = -1;
    }
    
    async loadNewOpponent() {
        console.log('loadNewOpponent');
        //await summonItem();
        const battleResult = await startBattle();
        if (battleResult.data.success == false) {
            return;
        }
        console.log(battleResult);
        const displayCoin = battleResult.data.myData.displayCoin;
        this.coinSlot.updateNumber(displayCoin);
        this.eggBtn.setEggStatus(displayCoin >= this.eggBtn.price ? true : false);
        this.myEquipments = battleResult.data.myData.equipments;
        console.log("myequipments" , this.myEquipments)
        this.inventorybg.updateEquipment(battleResult.data.myData.equipments);

        if (battleResult.data.opponent) {
            this.currentTurn = -1;
            this.battleInfo = battleResult.data.battleInfo;
            this.leftPlayer = this.add.spine(150, 500, battleResult.data.myData.skin + '-data', battleResult.data.myData.skin + '-atlas');
            this.updateRoosterEquipment(battleResult.data.myData.equipments, this.leftPlayer);

            /*const leftSkin = new Skin('custom');
            leftSkin.addSkin(this.leftPlayer.skeleton.data.findSkin('normal'));
            this.leftPlayer.skeleton.setSkin(leftSkin);*/
            this.leftPlayer.animationState.setAnimation(0, 'idle', true);
            console.log(battleResult.data.opponentData.skin)
            this.rightPlayer = this.add.spine(550, 500, battleResult.data.opponentData.skin + '-data', battleResult.data.opponentData.skin + '-atlas');
            this.updateRoosterEquipment(battleResult.data.opponentData.equipments, this.rightPlayer);
            this.rightPlayer.skeleton.setSkin(this.rightPlayer.skeleton.data.findSkin('normal'));
            this.rightPlayer.animationState.setAnimation(0, 'idle', true);
            this.rightPlayer.scaleX = -1;

            this.leftHP = new ProgressBar(this, 50, 140, 'hp_green', battleResult.data.myData.hp);
            this.rightHP = new ProgressBar(this, 470, 140, 'hp_red', battleResult.data.opponentData.hp);

            this.myName = this.add.text(this.leftHP.x, this.leftHP.y - 40, battleResult.data.myData.displayName, { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: '#fff', align: 'left' }).setOrigin(0, 0);
            this.oppName = this.add.text(this.rightHP.x + this.rightHP.width, this.rightHP.y - 40, battleResult.data.opponentData.displayName, { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: '#fff', align: 'right' }).setOrigin(1, 0);
        }
    }


    displaySummonItemData(itemData) {
        if (!itemData) {
            console.error('Summon Item Data is undefined or null');
            return;
        }
        if (this.summonItemText) {
            this.summonItemText.destroy();
        }
        const itemText = JSON.stringify(itemData, null, 2); 
        this.summonItemText = this.add.text(270, 550, itemText, {
            fontFamily: 'Arial',
            fontSize: 35,
            color: '#00FF00',
            wordWrap: { width: 620, useAdvancedWrap: true }
        });
        console.log(itemData);
    }
    
    updateRoosterEquipment(equipData, roosterSpine) {
        const customSkin = new Skin('custom');
        customSkin.addSkin(roosterSpine.skeleton.data.findSkin('normal'));
    
        for (var position in equipData) {
            var slotName = 'armor_' + position;
            console.log('slotName:', slotName);
            var attachmentName = 'armor_gold_' + position;
            if (position == 'head') {
                slotName = 'armor_helmet';
                attachmentName = 'armor_gold_helmet';
            }
            if (position == 'leg' || position == 'thigh') {
                slotName = position + '_r';
                attachmentName = slotName;
            }
            if(position == 'thighs'){
				slotName = 'thigh_r';
				attachmentName = slotName;
			}
    
            const slot = roosterSpine.skeleton.data.findSlot(slotName);
            console.log('slot:', slot);
            const attachment = roosterSpine.skeleton.data.findSkin(equipData[position].material).getAttachment(slot.index, attachmentName);
            customSkin.setAttachment(slot.index, attachmentName, attachment);
        }
    
        roosterSpine.skeleton.setSkin(customSkin);
    }
     
    createReward(maxParticle = 5) {
        const emitter = this.add.particles(550, 400, 'roosterbattle', {
            frame: 'token_rgold',
            bounce: 0.5,
            speed: { min: 600, max: 700 },
            maxVelocityX: 1200,
            maxVelocityY: 1200,
            angle: { min: -100, max: -80 },
            gravityY: 1600,
            lifespan: 4000,
            maxParticles: maxParticle,
            sortProperty: 'lifeT',
            sortOrderAsc: true,
        });

        emitter.addParticleBounds(0, 0, 700, 550, false, false, false, true);

        this.time.addEvent({
            callback: function () {
                emitter.gravityY = 0;
                const well = emitter.createGravityWell({
                    x: -550,
                    y: -550,
                    power: 10,
                    gravity: 1600,
                });
            },
            callbackScope: this,
            delay: 2000,
            loop: false,
        });
    }
    showVictoryScreen() {
        this.isShowVictoryScreen = true;
        if (this.overlay) { 
            this.isHavingModal = true; 
        } 
        this.showOverlay(0.5);
        if (this.victoryElements == null) {
            this.victoryElements = [];
            const victoryBg = this.add.image(360, 440, 'roosterbattle', 'victory_bg').setOrigin(0.5).setVisible(false).setDepth(1000);
            const victoryLight = this.add.image(360, 440, 'roosterbattle', 'victory_light').setOrigin(0.5).setVisible(false).setDepth(1000);
            const victoryText = this.add.image(360, 440, 'roosterbattle', 'victory_text').setOrigin(0.5).setVisible(false).setDepth(1000);
            this.victoryElements.push(victoryBg, victoryLight, victoryText);
        }
        this.victoryElements.forEach(element => element.setVisible(true));
        this.time.delayedCall(4150, () => {
            this.isShowVictoryScreen = false;
            this.victoryElements.forEach(element => element.setVisible(false));
            if (!this.isHavingModal) {
                this.hideOverlay(); 
            } 
        }, [], this);
    }
}