import { getHttpEndpoint } from '@orbs-network/ton-access';
import { TonClient } from "@ton/ton";


export function useTonClient() {
  return (
    async () =>
      new TonClient({
        endpoint: await getHttpEndpoint({ network: 'mainnet' }),
      })
  );
}
