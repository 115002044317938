import { Address, beginCell, Cell, Contract, contractAddress, ContractProvider, Sender, SendMode, Dictionary, toNano, TupleBuilder } from '@ton/core';

export type RoosterBattleConfig = {};

export function roosterBattleConfigToCell(config: RoosterBattleConfig): Cell {
    return beginCell().endCell();
}

export class RoosterBattle implements Contract {
    constructor(readonly address: Address, readonly init?: { code: Cell; data: Cell }) {}

    static createFromAddress(address: Address) {
        return new RoosterBattle(address);
    } 

    /**
     * User check-in
     * @param provider 
     * @param via Sender
     * @returns Promise<SendMessageResult & {result:void}>
     */
    async sendCheckin(provider: ContractProvider, via: Sender, amount: number){
        const messageBody = beginCell()
        .storeUint(2, 32) // op (op #2 = check in)
        .storeUint(0, 64) // query id
        .endCell();
        
        return await provider.internal(via, {
            value: toNano(amount), // send 0.2, receive back 0.1 - fee
            body: messageBody,
        });
    }

    /**
     * Get total  number of users
     * @param provider 
     * @returns Number
     */
    async getCount(provider: ContractProvider){
        const { stack } = await provider.get("info", []);
        return stack.readNumber();
    }

    /**
     * Check if an user has checked-in today or not
     * @param provider 
     * @param user 
     * @returns boolean
     */
    async getIsCheckedIn(provider: ContractProvider, user: Address){
        var tuple = new TupleBuilder();
        tuple.writeAddress(user);

        const { stack } = await provider.get("is_checked_in", tuple.build());
        return stack.readNumber() ? true : false;
    }
}