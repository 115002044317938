import { Scene } from 'phaser';

export class Hatch extends Scene {
    constructor() {
        super('Hatch');
    }

    init(data) {
        console.log("init data", data);
        this.upgradeEquip = data.upgradeEquip;
        this.currentEquip = data.currentEquip;
    }
    
    preload() {
        this.load.atlas('roosterbattle', 'assets/roosterbattle_main.png', 'assets/roosterbattle_main.json');
    }
    fontStyle = {
        fontFamily: 'Barlow Condensed',
        fontSize: 45,
        color: '#ffffff',
        stroke: '#000000',
        strokeThickness: 2,
        shadow: {
            offsetX: 2,
            offsetY: 2,
            color: '#000000',
            blur: 1,
            fill: true
        }
    }

    generateFontstyle(fontSize = 45) {
        var newFontStyle = this.fontStyle
        newFontStyle.fontSize = fontSize
        return newFontStyle  
    }
    create() {

        const { atk, def, name, level } = this.upgradeEquip.data;
        this.add.nineslice(60, 190, 'roosterbattle', 'popup_bg', 600, 770, 45, 45, 45, 45).setOrigin(0, 0).setInteractive();
        this.add.nineslice(90, 220, 'roosterbattle', 'popup_row_bg', 540, 340, 65, 65, 25, 35).setOrigin(0, 0).setInteractive();
        this.add.nineslice(90, 580, 'roosterbattle', 'popup_row_bg', 540, 340, 65, 65, 25, 35).setOrigin(0, 0).setInteractive();

        if(this.currentEquip){
            this.display_currentItem();
        }

        const buttonGreenNineslice = this.add.nineslice(370, 815, 'roosterbattle', 'button_green_normal', 200, 70, 30, 30, 20, 20).setOrigin(0, 0).setInteractive();
        buttonGreenNineslice.on('pointerup', async () => {
            const overlay = this.add.graphics();
            overlay.fillStyle(0x000000, 0.5);
            overlay.fillRect(0, 0, this.cameras.main.width, this.cameras.main.height);
            overlay.setDepth(1000);
            try {
                const itemData = await equipPendingItem();
                overlay.destroy();
                console.log(itemData);
                const mainMenu = this.scene.get('MainMenu');
                mainMenu.inventorybg.updateEquipment(itemData.data.equipments); 
                mainMenu.myEquipments = itemData.data.equipments; 
                mainMenu.inventorybg.updateEquipment(mainMenu.myEquipments); 
                mainMenu.hideOverlay();
                mainMenu.scene.resume();
                this.scene.stop('Hatch');
            } catch (error) {
                console.error('Error while fetching pending item:', error);
                overlay.destroy();
                const mainMenu = this.scene.get('MainMenu');
                mainMenu.hideOverlay();
                mainMenu.scene.resume();
            }
        });
        const buttonredNineslice = this.add.nineslice(150, 815, 'roosterbattle', 'button_red_normal', 200, 70, 30, 30, 20, 20).setOrigin(0, 0).setInteractive();
        buttonredNineslice.on('pointerup', async () => {
            this.scene.stop('Hatch');
            const mainMenu = this.scene.get('MainMenu');
            mainMenu.hideOverlay();
            mainMenu.scene.resume();
        });
        this.cameras.main.setViewport(0, 0, this.game.config.width, this.game.config.height);
        this.cameras.main.setBackgroundColor('rgba(0, 0, 0, 0.5)');
        this.cameras.main.on('pointerdown', () => {
            const mainMenu = this.scene.get('MainMenu');
            mainMenu.hideOverlay();
            mainMenu.scene.resume();
            this.scene.stop('Hatch');
        });

        this.add.nineslice(539, 210, 'roosterbattle', 'equip_label_blue', 120, 60, 15, 15, 15, 15).setOrigin(0, 0).setInteractive();
        this.add.nineslice(539, 571, 'roosterbattle', 'equip_label_red', 120, 60, 15, 15, 15, 15).setOrigin(0, 0);
        this.add.text(439, 827, 'Equip',  this.generateFontstyle(35));
        this.add.text(232, 827, 'Sell',  this.generateFontstyle(35));
        this.add.text(560, 217, 'Current',  this.generateFontstyle(34));
        this.add.text(575, 580, 'New',  this.generateFontstyle(34));
        const rarity = this.upgradeEquip.data.rarity || 'defaultrarity';
        var color = ['#FFFFFF', '#00FF00', '#00A7FD', '#FE0CFE', '#FF161F', '#FFF200'];
        this.add.text(270, 607, `${name}`, {
            fontFamily: 'Barlow Condensed',
            fontSize: '25px',
            fill: color[rarity],
            stroke: '#000000',
            strokeThickness: 2,
            shadow: {
                offsetX: 2,
                offsetY: 2,
                color: '#000000',
                blur: 1,
                fill: true
            }
        }).setOrigin(0);

        const rightAlignmentX = 550;  
        const textX = 270;
        this.add.text(textX, 650, 'Level', this.generateFontstyle(25)).setOrigin(0);
        this.add.text(rightAlignmentX, 650, `${level}`, this.generateFontstyle(25)).setOrigin(1, 0);
        this.add.text(textX, 690, 'ATK', this.generateFontstyle(25)).setOrigin(0);
        this.add.text(rightAlignmentX, 690, `${atk}`, this.generateFontstyle(25)).setOrigin(1, 0);
        this.add.text(textX, 730, 'DEF', this.generateFontstyle(25)).setOrigin(0);
        this.add.text(rightAlignmentX, 730, `${def}`, this.generateFontstyle(25)).setOrigin(1, 0);
    
        if (this.currentEquip)
        this.add.nineslice(107, 607, 'roosterbattle', 'equip_none_bg', 126, 126, 1, 1, 1, 1).setOrigin(0, 0);
        if (this.upgradeEquip.data) {
            const rarity = this.upgradeEquip.data.rarity || 'defaultrarity';
            var color = ['gray', 'green', 'blue', 'purple', 'red', 'yellow'];
            this.add.nineslice(110, 610,'roosterbattle', 'equip_rarity_' + color[rarity],120, 120, 1, 1, 1, 1).setOrigin(0, 0);
            let material = this.upgradeEquip.data.material || 'defaultMaterial';
            let position = this.upgradeEquip.data.image || 'defaultPosition';
            position = position.replace('_1', '');
            if (position === 'head') {
                position = 'helmet';
            }
            const frameName = capitalizeFirstLetter(material) + 'Armor/armor_' + material + '_' + position;
            const textureName = 'nguyetcungke-atlas!NguyetCungKe.png';
            const frm = this.textures.getFrame(textureName, frameName);
            if (frm) {
                this.add.nineslice(126, 623, textureName, frameName, 90, 90, 1, 1, 1, 1).setOrigin(0, 0);
            } 
        }
        this.add.text(150, 704, `Lv. ${level}`, this.generateFontstyle(27)).setOrigin(0);
        if(this.currentEquip){
            const atk2 = this.currentEquip.atk;
            const def2 = this.currentEquip.def;

            if(atk2 != atk){
                this.add.nineslice(567, 690, 'roosterbattle', atk2 < atk ? 'power_up' : 'power_down' , 30, 30, 1, 1, 1, 1).setOrigin(0, 0);
            }
            if(def2 != def){
                this.add.nineslice(567, 730, 'roosterbattle', def2 < def ? 'power_up' : 'power_down' , 30, 30, 1, 1, 1, 1).setOrigin(0, 0);
            }
        }
    }

    display_currentItem(){
        
        const atk2 = this.currentEquip.atk;
        const def2 = this.currentEquip.def;
        const name2 = this.currentEquip.name;
        const level2= this.currentEquip.level;
        this.add.nineslice(107, 307, 'roosterbattle', 'equip_none_bg', 126, 126, 1, 1, 1, 1).setOrigin(0, 0);
        const rarity = this.currentEquip.rarity || 'defaultrarity';
        var color = ['gray', 'green', 'blue', 'purple', 'red', 'yellow'];
        this.add.nineslice(110, 310,'roosterbattle', 'equip_rarity_' + color[rarity],120, 120, 1, 1, 1, 1).setOrigin(0, 0);
        let material = this.currentEquip.material || 'defaultMaterial';
        let position = this.currentEquip.image || 'defaultPosition';
        position = position.replace('_1', '');
        if (position === 'head') {
            position = 'helmet';
        }
        const frameName = capitalizeFirstLetter(material) + 'Armor/armor_' + material + '_' + position;
        const textureName = 'nguyetcungke-atlas!NguyetCungKe.png';
        const frm = this.textures.getFrame(textureName, frameName);
        if (frm) {
            this.add.nineslice(126, 323, textureName, frameName, 90, 90, 1, 1, 1, 1).setOrigin(0, 0);
        } 
        const raritycurrent = this.currentEquip.rarity || 'defaultrarity';
        var colorcurrent = ['#FFFFFF', '#00FF00', '#00A7FD', '#FE0CFE', '#FF161F', '#FFF200'];
        this.add.text(270, 307, `${name2}`, {
            fontFamily: 'Barlow Condensed',
            fontSize: '25px',
            stroke: '#000000',
            strokeThickness: 2,
            fill: colorcurrent[raritycurrent],
            shadow: {
                offsetX: 2,
                offsetY: 2,
                color: '#000000',
                blur: 1,
                fill: true
            }
        }).setOrigin(0);

        const rightAlignmentX = 550;  
        const textX = 270;
        this.add.text(textX, 360, 'Level', this.generateFontstyle(25)).setOrigin(0);
        this.add.text(rightAlignmentX, 360, `${level2}`, this.generateFontstyle(25)).setOrigin(1, 0);
        this.add.text(textX, 400, 'ATK', this.generateFontstyle(25)).setOrigin(0);
        this.add.text(rightAlignmentX, 400, `${atk2}`, this.generateFontstyle(25)).setOrigin(1, 0);
        this.add.text(textX, 440, 'DEF', this.generateFontstyle(25)).setOrigin(0);
        this.add.text(rightAlignmentX, 440, `${def2}`, this.generateFontstyle(25)).setOrigin(1, 0);
        this.add.text(150, 404, `Lv. ${level2}`, this.generateFontstyle(27)).setOrigin(0);}
}


