//Leaderboard.js
import { Scene } from 'phaser';

export class Leaderboard extends Scene {
    constructor() {
        super('Leaderboard');
    }

    preload() {
        this.load.atlas('roosterbattle', 'assets/roosterbattle_main.png', 'assets/roosterbattle_main.json');
    }

    fontStyle = {
        fontFamily: 'Barlow Condensed',
        fontSize: 45,
        color: '#ffffff',
        stroke: '#000000',
        strokeThickness: 2,
        shadow: {
            offsetX: 10,
            offsetY: 10,
            color: '#000000',
            blur: 10,
            fill: true
        }
    }

    generateFontstyle(fontSize = 45, offset = 10, color = '#ffffff', strokeThickness = 2) {
        return {
            ...this.fontStyle,
            fontSize,
            color,
            strokeThickness,
            shadow: {
                ...this.fontStyle.shadow,
                offsetX: offset,
                offsetY: offset
            }
        };
    }

    addText(x, y, text, fontSize, color, strokeThickness = 2, shadowOffset = 10) {
        return this.add.text(x, y, text, this.generateFontstyle(fontSize, shadowOffset, color, strokeThickness)).setOrigin(0.5, 0.5);
    }

    async create() {
        const closeButton = this.createCloseButton(this.cameras.main.width - 30, 1550);
        closeButton.disableInteractive();
        this.add.nineslice(30, 130, 'roosterbattle', 'popup_bg', 660, 1010, 45, 45, 45, 45).setOrigin(0, 0).setInteractive();
        this.add.nineslice(140, 90, 'roosterbattle', 'popup_title_bg', 440, 80, 80, 80, 40, 40).setOrigin(0, 0).setInteractive();
        this.add.text(265, 105, 'Leaderboard', this.generateFontstyle());
        try {
            const user = await getData();
            console.log('User data:', user);
        } catch (error) {
            console.error('Error fetching user data:', error);
            closeButton.setInteractive();
            return;
        }

        const listView = this.rexUI.add.scrollablePanel({
            x: this.cameras.main.centerX,
            y: this.cameras.main.centerY - 50,
            width: this.cameras.main.width - 280,
            height: this.cameras.main.height - 480,
            scrollMode: 0,
            background: this.rexUI.add.roundRectangle(0, 0, 2, 2, 10, 0x260e04),
            panel: {child: this.rexUI.add.sizer({orientation: 'y',space: { item: 20 },}),mask: { mask: true, padding: 1 },},
            space: {left: 10,right: 10,top: 0,bottom: 10,panel: 0,},mouseWheelScroller: {focus: true,speed: 0.7}}).layout();

        try {
            const leaderboardData = await getLeaderboard();
            console.log('Leaderboard Data:', leaderboardData);

            if (Array.isArray(leaderboardData.data)) {
                leaderboardData.data.forEach((entry, index) => {
                    const itemSizer = this.createLeaderboardEntry(entry, index + 1);
                    listView.getElement('panel').add(itemSizer, { padding: { bottom: -9 } });
                });
            } else {
                console.error('leaderboardData.data is not an array:', leaderboardData.data);
                closeButton.setInteractive();
                return;
            }
            this.createPlayerInfo(leaderboardData.myName, leaderboardData.myElo, leaderboardData.myPosition);
            listView.layout();
            listView.bottomChildOY = listView.panel - listView;
        } catch (error) {
            closeButton.setInteractive();
            return;
        }
        closeButton.setInteractive();
        this.input.on('wheel', (pointer, gameObjects, deltaX, deltaY, deltaZ) => {
            const newScrollY = listView.childOY - deltaY * 0.7;
            listView.childOY = Phaser.Math.Clamp(newScrollY, listView.topChildOY, listView.bottomChildOY);
            listView.layout(); 
        });
    }

    createLeaderboardEntry(entry, rank) {
        const itemSizer = this.rexUI.add.sizer({orientation: 'x',space: { item: 10 },});
        const background = this.add.nineslice(0, 0, 'roosterbattle', 'rank_row_bg', 580, 105, 10, 10, 10, 10).setOrigin(0.5, 0.5);
        const container = this.add.container(0, 0, [background]).setSize(background.width, background.height);
        const medalImage = this.createMedalImage(rank);
        if (medalImage) {container.add(medalImage);}

        const gridSizer = this.rexUI.add.gridSizer({column: 3,row: 1,columnProportions: [1, 6, 3],space: { column: 10 }});
        const rankText = this.addText(0, 0, `${rank}`, 40, this.getRankColor(rank), 4, 2);
        rankText.setX(rankText.x - 300); 
        const nameText = this.addText(0, 0, entry.name, 30, '#ffffff', 2, 2);
        nameText.setY(nameText.y - 20);
        nameText.setOrigin(0, 0.5);
        nameText.setX(nameText.x - 70);
        const scoreText = this.addText(-51, -20, `ELO: ${entry.elo?.toLocaleString() ?? 0}`, 30, '#ffff69', 2, 2).setOrigin(0);

        scoreText.setY(scoreText.y + 20); 
        scoreText.setX(scoreText.x - 20);
        gridSizer.add(rankText, 0, 0, 'left', { left: -480 });
        gridSizer.add(nameText, 0, 1, 'left',{ left: 800 });
        gridSizer.add(scoreText, 0, 2, 'left',{ left: 800 });
        itemSizer.add(gridSizer, { expand: true });
        container.add(itemSizer);
        itemSizer.layout();
        return container;
    }

    getRankColor(rank) {
        return rank === 1 ? '#FFFF99' : rank === 2 ? '#FFFFFF' : rank === 3 ? '#ecad7f' : '#ffffff';
    }

    createMedalImage(rank) {
        const medalKey = rank === 1 ? 'medal_1' : rank === 2 ? 'medal_2' : rank === 3 ? 'medal_3' : '';
        return medalKey ? this.add.image(-250, 0, 'roosterbattle', medalKey).setOrigin(0.5).setVisible(true) : null;
    }

    createPlayerInfo(myName, myElo, myPosition) {
        this.add.image(360, 1055, 'roosterbattle', 'rank_row_player_bg').setOrigin(0.5).setVisible(true);
        this.addText(110, 1055, `${myPosition}`, 40, '#ffffff', 2, 2).setOrigin(0.5);
        this.addText(288, 1020, myName, 30, '#ffffff', 2, 2).setOrigin(0);
        this.addText(289, 1060, `ELO: ${myElo.toLocaleString()}`, 30, '#ffff69', 0, 2).setOrigin(0);
        this.addText(360, 1200, 'Tap to close', 40, '#ffffff', 2, 2);
        //this.add.image(565, 1055, 'roosterbattle', 'rank_reward_bg').setOrigin(0.5).setVisible(true);
        //this.add.image(565, 1035, 'roosterbattle', 'rice_token').setOrigin(0.5).setVisible(true);
        //this.add.text(530, 1070, '+15/h', this.generateFontstyle(30,2));
    }

    createCloseButton(x, y) {
        const backButton = this.add.rectangle(400, 500, 800, 2000, 0xFF0000, 0).setInteractive();
        backButton.on('pointerdown', () => {
            if (!this.overlay) { 
                this.scene.stop('Leaderboard');
                const mainMenu = this.scene.get('MainMenu');
                mainMenu.hideOverlay();
                mainMenu.scene.resume();
            }
        });
        return backButton; 
    }  
}
