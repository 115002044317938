import { Scene } from 'phaser';

export class Boot extends Scene {
	constructor() {
		super('Boot');
	}

	preload() {
		//  The Boot Scene is typically used to load in any assets you require for your Preloader, such as a game logo or background.
		//  The smaller the file size of the assets, the better, as the Boot Scene itself has no preloader.
		this.load.image('logo-512', './assets/rooster_battle_ton_logo.png');

		this.load.rexWebFont({
			google: {
				families: ['Barlow Condensed'],
			},
			// testString: undefined,
			// testInterval: 20,
		});
	}

	create() {
		this.scene.start('Preloader');
	}
}