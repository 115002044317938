// File: scenes/Popup_equipSlot.js
import Phaser from 'phaser';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export class Popup_equipSlot extends Phaser.Scene {
    constructor(atlas = 'main') {
        super({ key: 'Popup_equipSlot' });
    }

    init(data) {
        this.equipData = data.equipData || {}; // Kiểm tra dữ liệu trang bị
        this.roosterName = data.roosterName || 'UnnamedRooster'; 
        this.equipPosition = data.equipPosition || 'UnknownPosition'; 
    }

    preload() {
        this.load.atlas('roosterbattle', 'assets/roosterbattle_main.png', 'assets/roosterbattle_main.json');
    }
    fontStyle = {
        fontFamily: 'Barlow Condensed',
        fontSize: 45,
        color: '#ffffff',
        stroke: '#000000',
        strokeThickness: 2,
        shadow: {
            offsetX: 2,
            offsetY: 2,
            color: '#000000',
            blur: 1,
            fill: true
        }
    }

    generateFontstyle(fontSize = 45) {
        var newFontStyle = this.fontStyle
        newFontStyle.fontSize = fontSize
        return newFontStyle  
    }
    create() {

        this.add.nineslice(60, 190, 'roosterbattle', 'popup_bg', 600, 770, 45, 45, 45, 45).setOrigin(0, 0).setInteractive();
        this.add.nineslice(90, 220, 'roosterbattle', 'popup_row_bg', 540, 710, 85, 20, 25, 35).setOrigin(0, 0).setInteractive();
        //this.add.nineslice(109, 257, 'roosterbattle', 'equip_lock_bg', 126, 126, 1, 1, 1, 1).setOrigin(0, 0);
        this.add.nineslice(109, 257, 'roosterbattle', 'equip_none_bg', 126, 126, 1, 1, 1, 1).setOrigin(0, 0).setInteractive();

        if (this.equipData) {
            const rarity = this.equipData.rarity || 'defaultrarity';
            var color = ['gray', 'green', 'blue', 'purple', 'red', 'yellow'];
            this.add.nineslice(112, 260,'roosterbattle', 'equip_rarity_' + color[rarity],120, 120, 1, 1, 1, 1).setOrigin(0, 0);
        }
        
        if (this.equipData) {
            let material = this.equipData.material || 'defaultMaterial';
            let position = this.equipData.image || 'defaultPosition';
            position = position.replace('_1', '');
            if (position === 'head') {
                position = 'helmet';
            }
            const frameName = capitalizeFirstLetter(material) + 'Armor/armor_' + material + '_' + position;
            const textureName = 'nguyetcungke-atlas!NguyetCungKe.png';
            const frm = this.textures.getFrame(textureName, frameName);
            if (frm) {
                this.add.nineslice(128, 273, textureName, frameName, 90, 90, 1, 1, 1, 1).setOrigin(0, 0);
            } 
        }

        const rarity = this.equipData.rarity || 'defaultrarity';
        var color = ['#FFFFFF', '#00FF00', '#00A7FD', '#FE0CFE', '#FF161F', '#FFF200'];
        if (this.equipData) {
            this.add.text(270, 260, `${this.equipData.name}`, {
                fontFamily: 'Barlow Condensed',
                fontSize: '30px',
                fill: color[rarity],
                strokeThickness: 2,
                stroke: '#000000',
                shadow: {
                    offsetX: 2,
                    offsetY: 2,
                    color: '#000000',
                    blur: 1,
                    fill: true
                }
            }).setOrigin(0);
            const rightAlignmentX = 540;  // Adjust this value as needed for alignment
            const textX = 270;
            this.add.text(158, 360, `Lv.${this.equipData.level}`, this.generateFontstyle(23)).setOrigin(0);

            this.add.text(textX, 320, 'ATK', this.generateFontstyle(30)).setOrigin(0);
            this.add.text(rightAlignmentX, 320, `${this.equipData.atk}`, this.generateFontstyle(30)).setOrigin(1, 0);
    
            this.add.text(textX, 375, 'DEF', this.generateFontstyle(30)).setOrigin(0);
            this.add.text(rightAlignmentX, 375, `${this.equipData.def}`, this.generateFontstyle(30)).setOrigin(1, 0);
       
    
    
        } else {
            this.add.text(270, 290, 'No Equipment Data', this.generateFontstyle(30)).setOrigin(0);
        }

        this.add.nineslice(140, 150, 'roosterbattle', 'popup_title_bg',  440, 80, 80, 80, 40, 40, 10).setOrigin(0, 0);
        this.add.text(260, 165, `${this.roosterName} ${capitalizeFirstLetter(this.equipPosition)}`, this.generateFontstyle(45));
        this.add.nineslice(275, 795, 'roosterbattle', 'button_brown_disable', 160, 65, 30, 30, 5, 5).setOrigin(0, 0);
        this.add.text(300, 805, ' Enhance ', this.generateFontstyle(32));
        this.add.text(300, 860, 'Coming soon', this.generateFontstyle(25));
        this.add.text(270, 970, 'Tap to close', this.generateFontstyle(40))
    }
}