export default class NestEgg extends Phaser.GameObjects.Container {
    constructor(scene, x, y, atlas = 'main') {
        super(scene, x, y);
        this.btnName = "nest_egg";
        this.isEggBtnEnabled = true; 
        this.isClicked = false;
        scene.add.existing(this);
        
        this.currentatlas = atlas
        const nestBack = scene.add.image(0, 0, atlas, 'nest_back');
        this.eggIcon = scene.add.image(0, -30, atlas, 'egg_icon'); 

        const nestFront = scene.add.image(0, 0, atlas, 'nest_font');
        const hatchText = scene.add.image(0, 50, atlas, 'hatch_text');
        const hatch_currency_bg = scene.add.nineslice(0, 0, atlas, 'hatch_currency_bg', +90, 900,30, 10, 0, 0);

        this.price = 100;
        this.text100 = scene.add.text(-10, -17, this.price, { fontFamily: 'Barlow Condensed', fontSize: '32px', fill: '#ffffff',
            stroke: '#000000',
            strokeThickness: 2,
            shadow: {
                offsetX: 10,
                offsetY: 10,
                color: '#000000',
                blur: 10,
                fill: true
            }})

        this.setSize(nestBack.width, nestBack.height * 2.7);
        this.setInteractive();

        this.add(nestBack);
        this.add(this.eggIcon);
        this.add(nestFront);
        this.add(hatchText);
        this.add(hatch_currency_bg);
        this.add(this.text100);

        this.on('pointerdown', function() {
            this.scaleX = this.scaleY = 0.6;
            this.isClicked = true;
    });
        
        this.on('pointerup', function() {
            this.scaleX = this.scaleY = 1;
            this.isClicked = false;
        });
        
        this.on('pointerout', function() {
            if (this.isClicked) {
                this.emit('pointerup');
            }
            this.scaleX = this.scaleY = 1;
            this.isClicked = false;
        });
    }

    setEggStatus(status = true){ 
        this.eggIcon.setTexture('main', "egg_icon"+ (status? "" : "_disable"))
    }
    
    setEggPrice(price){ 
        this.text100.setText(price)
    }

    shakeEgg() {
        if (!this.isEggBtnEnabled) {
            console.log('Egg button is disabled, shaking effect will not start.');
            return;
        }

        // Thêm hiệu ứng rung lắc với rexShakePosition
        this.shakeEffect = this.scene.plugins.get('rexShakePosition').add(this.eggIcon, {
            duration: 5000,
            magnitude: 6,
            axis: 1
        });
            this.shakeEffect.shake();
    }
      
    stopShakeEffect(){
        this.shakeEffect.stop()
        this.eggIcon.x = 0;
        this.eggIcon.y = -30;
    }
}
